import React from 'react';
import SecurityKeyViewer from './SecurityKeyViewer';
import { useParams } from 'react-router-dom';

const DynamicSecurityKeyViewer = () => {
  const { assetId } = useParams();
  
  const getImagePaths = () => {
    // Extract the number from the vault ID (e.g., 'vault1' -> '1')
    const vaultNumber = assetId.replace('vault', '');
    return {
      front: `/images/${vaultNumber}_front.jpg`,
      back: `/images/${vaultNumber}_back.jpg`
    };
  };

  return <SecurityKeyViewer imagePaths={getImagePaths()} />;
};

export default DynamicSecurityKeyViewer;
