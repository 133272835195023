import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import SecurityKeyViewer from './components/SecurityKeyViewer';
import DynamicSecurityKeyViewer from './components/DynamicSecurityKeyViewer';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          {/* Redirect root to vault1 */}
          <Route path="/" element={<Navigate to="/vault1" replace />} />
          
          {/* Dynamic route for all vaults */}
          <Route path="/:assetId" element={<DynamicSecurityKeyViewer />} />
              }}
            />
          } />
        </Routes>
      </div>
    </Router>
  );
}

export default App;